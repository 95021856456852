<script setup lang="ts">
import { onMounted, onBeforeUnmount } from 'vue';
import { useOverlayStack } from '@/composables/overlay-stack';

interface Props {
  minWidth?: number;
  size?: 'default' | 's';
}

const props = withDefaults(defineProps<Props>(), {
  minWidth: 0,
  size: 'default',
});

const { addToStack, removeFromStack } = useOverlayStack();

onMounted(() => {
  addToStack();
});

onBeforeUnmount(() => {
  removeFromStack();
});
</script>

<template>
  <div
    class="dropdown-menu"
    :class="`dropdown-menu--${props.size}`">
    <slot />
  </div>
</template>

<style>
.dropdown-menu {
  --padding: var(--space-2);
  min-width: calc(v-bind('props.minWidth') * 1px);

  &.dropdown-menu--s {
    --padding: var(--space-1);
    margin: calc(-1 * var(--space-1));

    * {
      font-size: var(--font-size-s);
    }
  }

  .simple-confirmation-popper {
    display: block;
  }

  > div {
    padding: var(--padding) 0;
    border-bottom: 1px solid var(--color-gray-light);

    &.dropdown-menu-header {
      padding: var(--space-1) var(--space-2) var(--space-3);
    }

    &:first-child:not(.dropdown-menu-header) {
      padding-top: 0;
    }

    &:last-child {
      padding-bottom: 0;
      border-bottom: none;
    }

    a {
      text-decoration: none;
      display: block;
      padding: var(--space-2);
      color: var(--color-text-base);
      user-select: none;

      &:hover {
        background: var(--color-gray-ultra-light);
      }
    }
  }
}
</style>
