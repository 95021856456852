import { createHead } from '@unhead/vue';

const head = createHead();
head.push({
  titleTemplate: (title) => (title ? `${title} | kcalculator` : 'kcalculator'),
});

export {
  head,
};
