import {
  collection, type DocumentData, DocumentReference, DocumentSnapshot, type Firestore,
} from 'firebase/firestore';

// Influenced by a great article from Jamie Curnow
// https://medium.com/swlh/using-firestore-with-typescript-65bd2a602945
const converter = <T extends DocumentData>() => ({
  toFirestore: (data: T) => data,
  fromFirestore: (snapshot: DocumentSnapshot) => snapshot.data() as T,
});

export function typedCollection<T extends DocumentData>(db: Firestore, collectionPath: string) {
  return collection(db, collectionPath).withConverter(converter<T>());
}

export function typedSubCollection<T extends DocumentData>(parentCollection: DocumentReference<any, any>, path: string) {
  return collection(parentCollection, path).withConverter(converter<T>());
}
