import type {
  Food, MaybeUndefined, NutrientField, NutrientFieldValues, Product,
} from './typings';
import { roundNumber } from './util';

// BLS ID of water record (used for water-intake tracking in mobile app)
export const WATER_ID = 'N100000';

export const nutrientFields = [
  'energyKilojoules',
  'energyCal',
  'fat',
  'fattyAcidsSaturated',
  'fattyAcidsMonounsaturated',
  'fattyAcidsPolyunsaturated',
  'cholesterol',
  'carbohydrates',
  'sugar',
  'starch',
  'dietaryFiber',
  'protein',
  'salt',
  'alcohol',
  'water',
  'vitA_Betacarotene',
  'vitB1',
  'vitB2',
  'vitB6',
  'vitB12',
  'vitB3',
  'vitB9',
  'vitB5',
  'vitC',
  'vitD',
  'vitE',
  'kalium',
  'sodium',
  'chloride',
  'calcium',
  'magnesium',
  'phosphor',
  'iron',
  'iodine',
  'zinc',
] as const; // because https://stackoverflow.com/a/59857409/980055

export const big7: NutrientField[] = ['energyCal', 'fat', 'fattyAcidsSaturated', 'carbohydrates', 'sugar', 'protein', 'salt'];

export const big8: NutrientField[] = [...big7, 'dietaryFiber'];

export const big10: NutrientField[] = [...big8, 'fattyAcidsMonounsaturated', 'fattyAcidsPolyunsaturated'];

export const microNutrientFields: NutrientField[] = [
  'calcium',
  'kalium',
  'sodium',
  'magnesium',
  'chloride',
  'phosphor',
  'iron',
  'iodine',
  'zinc',
  'vitA_Betacarotene',
  'vitB1',
  'vitB2',
  'vitB3',
  'vitB5',
  'vitB6',
  'vitB9',
  'vitB12',
  'vitC',
  'vitD',
  'vitE',
];

export const diets = [
  'vegetarian',
  'vegan',
  'lowCarb',
  'lowFat',
  'ketogenic',
  'paleo',
  'lactoseFree',
  'glutenFree',
  'fodmap',
  'sorbitolFree',
  'fructoseFree',
] as const;

export const nutrientFieldMultiplicationFactors: {
  [field in NutrientField]: number
} = {
  energyKilojoules: 1,
  energyCal: 0.001,
  fat: 0.001,
  fattyAcidsSaturated: 0.001,
  fattyAcidsMonounsaturated: 0.001,
  fattyAcidsPolyunsaturated: 0.001,
  cholesterol: 1,
  carbohydrates: 0.001,
  sugar: 0.001,
  starch: 0.001,
  dietaryFiber: 0.001,
  protein: 0.001,
  salt: 0.001,
  alcohol: 0.001,
  water: 0.001,
  vitA_Betacarotene: 0.000001,
  vitB1: 0.000001,
  vitB2: 0.000001,
  vitB6: 0.000001,
  vitB12: 0.000001,
  vitB3: 0.000001,
  vitB9: 0.000001,
  vitB5: 0.000001,
  vitC: 0.000001,
  vitD: 0.000001,
  vitE: 0.000001,
  kalium: 0.000001,
  sodium: 0.000001,
  chloride: 0.000001,
  calcium: 0.000001,
  magnesium: 0.000001,
  phosphor: 0.000001,
  iron: 0.000001,
  iodine: 0.000001,
  zinc: 0.000001,
};

export const foodCategories = [
  'Alkoholfreie Getränke',
  'Alkoholhaltige Getränke',
  'Aufstrich und Aufschnitt', // new since BLS
  'Backwaren', // new since BLS
  'Brote und Brotwaren',
  'Eier',
  'Fette und Öle',
  'Fisch',
  'Fleisch',
  'Fleischersatz',
  'Fertiggerichte', // new since BLS
  'Hülsenfrüchte', // new since BLS
  'Cerealien', // was Flocken, Kleie und Keime
  'Obst (Früchte)', // was Früchte
  'Pilze', // new since BLS
  'Gemüse',
  'Gerichte',
  'Getreide',
  'Gewürze, Kräuter und Würzmittel', // was Gewürze/Kräuter
  'Milchprodukte',
  'Nüsse, Samen und Sprossen', // was Nüsse
  'Snacks und Süßigkeiten', // before "Snacks" and "Süßigkeiten" existed separately
  'Sonstige',
  'Teigwaren', // new since BLS
] as const;

interface Options {
  decimals: number,
  applyMultiplicationFactors: boolean,
}

export function getCalculatedFieldValue(
  field: NutrientField,
  rawValue: MaybeUndefined<number>,
  options: Options = {
    decimals: 3,
    applyMultiplicationFactors: true,
  },
): number {
  const value = !options.applyMultiplicationFactors
    ? rawValue
    : ((rawValue ?? 0) * nutrientFieldMultiplicationFactors[field]);

  return roundNumber(value ?? 0, options?.decimals);
}

export function getFormattedFieldValue(
  field: NutrientField,
  value: MaybeUndefined<number>,
  options: Options = {
    decimals: 3,
    applyMultiplicationFactors: true,
  },
): string {
  return new Intl.NumberFormat('de-DE').format(getCalculatedFieldValue(field, value, options));
}

export function recordIsProduct(record: Record<string, any>): record is Product {
  // Mobile app throws "Property 'source' comes from an index signature, so it must be accessed with ['source'].", hence ignoring the rule
  // eslint-disable-next-line @typescript-eslint/dot-notation
  return Boolean(record) && 'source' in record && ['open_food_facts', 'import'].includes(record['source'] ?? '');
}

export function extractNutrients(record: Partial<NutrientFieldValues>): Partial<NutrientFieldValues> {
  return nutrientFields.reduce<Partial<NutrientFieldValues>>(
    (acc, curr) => {
      if (record.hasOwnProperty(curr)) {
        acc[curr] = record[curr];
      }

      return acc;
    },
    {},
  );
}

export function createFoodHelper(record: Food | Product) {
  const isProduct = recordIsProduct(record);

  return {
    data: record,
    id: record.id,
    calculatedValue: (field: NutrientField): number => getCalculatedFieldValue(
      field,
      field in record ? ((record as NutrientFieldValues)[field]) : undefined,
    ),
    fieldValue: (field: NutrientField): string => getFormattedFieldValue(
      field,
      field in record ? ((record as NutrientFieldValues)[field]) : undefined,
    ),
    isInUserPool: isProduct ? false : record.source === 'user_pool',
    isProduct,
  };
}
