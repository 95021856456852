<script setup lang="ts">
import ToggleControl from '@/components/fields/ToggleControl.vue';
import { usePayment } from '@/composables/firebase/payment';

const { subscriptionPeriod, setSubscriptionPeriod, canChoosePeriod } = usePayment();

const handleUpdate = (val: boolean) => {
  setSubscriptionPeriod(val ? 'yearly' : 'monthly');
};
</script>

<template>
  <div class="subscription-period-control flex justify-center">
    <ToggleControl
      :disabled="!canChoosePeriod"
      :model-value="subscriptionPeriod === 'yearly'"
      @update:model-value="handleUpdate"
      label="Jährliche Zahlung (-20% Rabatt)" />
  </div>
</template>

<style>
.subscription-period-control {
  font-weight: var(--font-weight-bold);
}
</style>
