<script setup lang="ts">
import { computed, ref } from 'vue';
import { lastValueFrom } from 'rxjs';
import BaseModal from '@/components/BaseModal.vue';
import { useGamification } from '@/composables/gamification';
import { useAuth } from '@/composables/firebase/auth';
import BaseButton from '@/components/BaseButton.vue';
import { updateConsultant } from '@/lib/firebase/store';
import { redeemLevelBenefits$ } from '@/lib/firebase/functions';
import { useSystemMessage } from '@/composables/system-message';
import { useConfetti } from '@/composables/confetti';

const { user } = useAuth();
const { currentLevel, levelIcon } = useGamification();
const { setSystemMessage } = useSystemMessage();
const { celebrate } = useConfetti();

const seenForLevel = computed(() => user.value?.profile?.gamification?.notificationSeenForLevel ?? 0);
const redeemedLevel = computed(() => user.value?.profile?.gamification?.redeemedLevel ?? 0);

const shouldShow = computed(() => {
  if (user.value) {
    if (currentLevel.value > seenForLevel.value && currentLevel.value > redeemedLevel.value) {
      return true;
    }
  }

  return false;
});

const setSeenFlag = async () => {
  if (user.value) {
    updateConsultant(user.value.authUser.uid, {
      gamification: {
        notificationSeenForLevel: currentLevel.value,
      },
    });
  }
};

const close = async () => {
  await setSeenFlag();
};

const isRedeeming = ref(false);

const redeemLevelBenefits = async () => {
  isRedeeming.value = true;
  await lastValueFrom(redeemLevelBenefits$(currentLevel.value))
    .then(() => {
      isRedeeming.value = false;
      setSeenFlag();
      setSystemMessage('gamification.redemptionSucceeded', 'success');
    })
    .catch(() => {
      isRedeeming.value = false;
      setSystemMessage('gamification.redemptionFailed', 'error');
    });
};
</script>

<template>
  <BaseModal
    class="level-up-notification"
    :title="$t('gamification.levelUpNotification.title')"
    v-if="shouldShow"
    @close="close"
    @show="celebrate">
    <template #default>
      <div class="icon-wrapper">
        <component :is="levelIcon" />
      </div>

      <p
        class="text-l"
        v-html="$t('gamification.levelUpNotification.description', { currentLevel })" />
      <p class="mt-3">
        {{ $t('gamification.levelUpNotification.advantagesDescription') }}
      </p>
      <div v-html="$t(`gamification.advantages.level${currentLevel}`)" />
    </template>
    <template #actions>
      <BaseButton
        class="btn btn-primary"
        :loading="isRedeeming"
        @click="redeemLevelBenefits">
        {{ $t('gamification.levelUpNotification.redeemCta') }}
      </BaseButton>
    </template>
  </BaseModal>
</template>

<style>
.level-up-notification {
  .modal-body {
    text-align: center;
  }

  .icon-wrapper {
    margin: 0 auto var(--space-4);
    padding: var(--space-3);
    border-radius: 100%;
    border: 4px solid var(--color-primary);
    width: 7rem;
    height: 7rem;
  }

  ul {
    margin: var(--space-3) auto 0;
    max-width: 17rem;
    display: inline-block;
    text-align: left;
    padding: 0;
    font-size: var(--font-size-m);
    line-height: var(--line-height-s);

    li:not(:last-child) {
      margin-bottom: var(--space-2);
    }
  }
}
</style>
