<!-- eslint-disable -->
<template>
  <svg
    class="kc-icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 250 250">
    <polygon
      points="225 225 225 50 125 50 100 25 25 25 25 225 225 225" />
    <polyline
      points="225 100 125 100 100 75 25 75" />
  </svg>
</template>
