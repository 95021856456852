<script setup lang="ts">
import { ref } from 'vue';
import CurrentLevel from '@/components/gamification/CurrentLevel.vue';
import GamificationOverviewModal from '@/components/gamification/GamificationOverviewModal.vue';

const showDetailsModal = ref(false);
</script>

<template>
  <div>
    <CurrentLevel
      v-bind="$attrs"
      @click.prevent="showDetailsModal = true" />

    <GamificationOverviewModal
      v-if="showDetailsModal"
      @close="showDetailsModal = false" />
  </div>
</template>
