import { createI18n } from 'vue-i18n';
import { setLocale } from 'yup';

import de from '@/lang/de.json';
import en from '@/lang/en.json';

type MessageSchema = typeof de;

export const i18n = createI18n<[MessageSchema], 'de' | 'en'>({
  legacy: false,
  locale: 'de',
  fallbackLocale: 'de',
  globalInjection: true,
  messages: { de, en },
});

export const { global: { t } } = i18n;

setLocale({
  string: {
    email: () => t('generic.validations.email'),
  },
  boolean: {
    isValue: () => t('generic.validations.required'),
  },
  mixed: {
    required: () => t('generic.validations.required'),
  },
  number: {
    min: ({ min }) => t('generic.validations.numberMin', { min }),
    max: ({ max }) => t('generic.validations.numberMax', { max }),
  },
});
