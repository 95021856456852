<!-- eslint-disable -->
<template>
  <svg
    class="kc-icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 250 250">
    <circle
      cx="125"
      cy="112.5"
      r="37.5" />
    <path
      d="M193.75,194.98c-11.58-26.48-38-44.98-68.75-44.98s-57.17,18.51-68.75,44.98" />
    <circle
      cx="125"
      cy="125"
      r="100" />
  </svg>
</template>
