<!-- eslint-disable -->
<template>
  <svg
    class="kc-icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 250 250">
    <rect
      x="25"
      y="25"
      width="200"
      height="200" />
    <line
      x1="175"
      y1="75"
      x2="75"
      y2="75" />
    <line
      x1="75"
      y1="125"
      x2="175"
      y2="125" />
    <line
      x1="75"
      y1="175"
      x2="175"
      y2="175" />
  </svg>
</template>
