<script setup lang="ts">
import { computed, watchEffect } from 'vue';
import { useSystemMessage } from '@/composables/system-message';
import AppModal from '@/components/generic/AppModal.vue';
import { useConfetti } from '@/composables/confetti';

const { systemMessage, clearSystemMessage } = useSystemMessage();
const { celebrate } = useConfetti();

const hasMessage = computed(() => !!systemMessage.value);

watchEffect(() => {
  if (systemMessage.value?.shouldCelebrate) {
    celebrate();
  }
});

const icon = computed(() => {
  if (systemMessage.value) {
    switch (systemMessage.value.type) {
      case 'info':
        return 'info';
      case 'success':
        return ['far', 'circle-check'];
      case 'warning':
        return 'triangle-exclamation';
      case 'error':
        return 'triangle-exclamation';
      default:
        return '';
    }
  }

  return undefined;
});
</script>

<template>
  <AppModal
    class="system-message"
    :class="[`system-message--${systemMessage.type}`]"
    v-if="hasMessage && systemMessage"
    @close="clearSystemMessage"
    show-close-action>
    <template
      v-if="icon"
      #header>
      <div
        class="icon">
        <font-awesome-icon :icon="icon" />
      </div>
    </template>

    <template #default>
      <div>
        {{ systemMessage.message() }}
      </div>
    </template>
  </AppModal>
</template>

<style>
.system-message {
  .modal-header {
    position: relative;

    .icon {
      font-size: var(--font-size-2xl);
      text-align: center;
    }

    > .btn {
      position: absolute;
      right: var(--space-3);
    }
  }

  .modal-body {
    text-align: center;
  }

  &--info {
    .modal-header {
      background-color: var(--color-gray-ultra-light);

      &, > .btn {
        color: var(--color-secondary);
      }
    }
  }

  &--success {
    .modal-header {
      background-color: var(--color-success);

      &, > .btn {
        color: var(--color-success-contrast);
      }
    }
  }

  &--warning {
    .modal-header {
      background-color: var(--color-warning);

      &, > .btn {
        color: var(--color-warning-contrast);
      }
    }
  }

  &--error {
    .modal-header {
      background-color: var(--color-danger);

      &, > .btn {
        color: var(--color-danger-contrast);
      }
    }
  }
}
</style>
