<!-- eslint-disable -->
<template>
  <svg
    class="kc-icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 250 250">
    <polygon
      points="25 25 25 200 100 200 125 225 150 200 225 200 225 25 25 25" />
    <line
      x1="175"
      y1="75"
      x2="75"
      y2="75" />
    <line
      x1="75"
      y1="125"
      x2="175"
      y2="125" />
  </svg>
</template>
