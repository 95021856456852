import { treaty } from '@elysiajs/eden';
import { filter, lastValueFrom, take } from 'rxjs';
import type { App } from '@kcalc/api';
import { logout, user$ } from '@/lib/firebase/auth';

export const client = treaty<App>(import.meta.env.VITE_API_URL ?? 'localhost:3000', {
  async onRequest() {
    const user = await lastValueFrom(user$.pipe(
      filter(Boolean),
      take(1),
    ));

    if (user) {
      const token = await user.authUser.getIdToken();

      return {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
    }

    return undefined;
  },
  async onResponse(response) {
    // force logout on 'unauthorized' response
    if (response.status === 401) {
      await logout();
    }
  },
});

export const apiClient = client.api.v1;
