import { ref, computed } from 'vue';
import { DateTime } from 'luxon';
import type {
  MaybeUndefined, SubscriptionPlan, SubscriptionPlanPeriod, UpgradeInfoResponse,
} from '@kcalc/lib/browser';
import { lastValueFrom } from 'rxjs';
import { useAuth } from '@/composables/firebase/auth';
import { useAppConfig } from '@/composables/app-config';
import { getUpgradeInfo$ } from '@/lib/firebase/functions';

const { user, hasActiveTrial, hasActiveSubscription } = useAuth();
const { getConfig } = useAppConfig();

const subscriptionPeriod = ref<SubscriptionPlanPeriod>('yearly');
const setSubscriptionPeriod = (period: SubscriptionPlanPeriod) => {
  subscriptionPeriod.value = period;
};

const subscriptionPlansRef = computed<MaybeUndefined<SubscriptionPlan[]>>(() => getConfig('subscriptionPlans'));

const subscriptionPlan = computed(() => {
  if (hasActiveSubscription.value) {
    return subscriptionPlansRef.value?.find((plan) => plan.code === user.value?.profile?.subscription.plan);
  }

  return null;
});

const subscription = computed(() => {
  if (hasActiveSubscription.value) {
    return user.value?.profile?.subscription;
  }

  return null;
});

const previousSubscription = computed(() => {
  if (user.value?.profile?.subscription
  && ['canceled', 'suspended'].includes(user.value.profile.subscription.status)) {
    return user.value.profile.subscription;
  }

  return null;
});

const trialExpiration = computed(() => (
  hasActiveTrial && user.value?.profile?.trialUntil
    ? DateTime
      .fromISO(user.value.profile.trialUntil)
      .toLocaleString(DateTime.DATETIME_SHORT)
    : null
));

const upgradeInfo = ref<MaybeUndefined<UpgradeInfoResponse>>();
const fetchUpgradeInfo = async () => {
  if (!upgradeInfo.value && subscriptionPlan.value?.code) {
    upgradeInfo.value = await lastValueFrom(getUpgradeInfo$({
      currentPlanCode: subscriptionPlan.value.code,
      newPlanCode: 'business',
      subscriptionId: user.value?.profile?.subscription?.id as string,
    }));
  }
};

const canChoosePeriod = computed(() => {
  if (!subscription.value || subscription.value.status !== 'active') {
    return true;
  }

  return !subscription.value.period || subscription.value.period === 'monthly';
});

const mobileSubscription = computed(() => {
  if (user.value?.profile?.mobileSubscription) {
    return user.value.profile.mobileSubscription;
  }

  return null;
});

const hasActiveMobileSubscription = computed(() => mobileSubscription.value?.status === 'active');

export function usePayment() {
  return {
    subscriptionPlans: subscriptionPlansRef,
    subscriptionPlan,
    subscription,
    trialExpiration,
    previousSubscription,
    fetchUpgradeInfo,
    upgradeInfo,
    subscriptionPeriod,
    setSubscriptionPeriod,
    canChoosePeriod,
    hasActiveMobileSubscription,
    mobileSubscription,
  };
}
