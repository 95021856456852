<script setup lang="ts">
import SubscriptionPlanBox from './SubscriptionPlanBox.vue';
import { usePayment } from '@/composables/firebase/payment';
import SubscriptionPeriodControl from '@/components/payment/SubscriptionPeriodControl.vue';

const { subscriptionPlans, subscriptionPeriod } = usePayment();
</script>

<template>
  <div class="subscription-plans">
    <SubscriptionPeriodControl />
    <div
      class="boxes-wrapper"
      v-if="subscriptionPlans">
      <div
        v-for="plan in subscriptionPlans"
        :key="plan.code">
        <SubscriptionPlanBox
          :code="plan.code"
          :period="subscriptionPeriod"
        />
      </div>
    </div>
    <div class="text-center text-m mt-5 pb-5">
      {{ $t('SubscriptionPlans.vatInfo') }}
    </div>
  </div>
</template>

<style>
.subscription-plans {
  .subscription-period-control {
    margin-bottom: var(--space-2);
  }

  .boxes-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    > div {
      padding: var(--space-2);
    }
  }
}
</style>
