<script setup lang="ts">
import type { PropType } from 'vue';
import { computed, ref } from 'vue';
import type { SubscriptionPlanCode, SubscriptionPlanPeriod } from '@kcalc/lib/browser';
import { subscriptionBenefitPercentage } from '@kcalc/lib/browser';
import AppCard from '@/components/generic/AppCard.vue';
import BaseButton from '@/components/BaseButton.vue';
import { usePayment } from '@/composables/firebase/payment';
import { useCheckout } from '@/composables/checkout';
import { useGamification } from '@/composables/gamification';

const props = defineProps({
  code: {
    type: String as PropType<SubscriptionPlanCode>,
    required: true,
  },
  period: {
    type: String as PropType<SubscriptionPlanPeriod>,
    required: false,
    default: 'monthly',
  },
  showBuyCta: {
    type: Boolean,
    required: false,
    default: true,
  },
});

const { subscriptionPlans } = usePayment();
const { redeemedLevel } = useGamification();

const additionalInformationVisible = ref<boolean>(false);

const toggleAdditionalInformationVisibility = () => {
  additionalInformationVisible.value = !additionalInformationVisible.value;
};

const subscriptionPlan = computed(() => subscriptionPlans.value?.find((plan) => plan.code === props.code));

const name = computed(() => subscriptionPlan.value?.name);
const benefit = computed(() => (
  redeemedLevel.value > 0
    ? subscriptionBenefitPercentage[redeemedLevel.value]
    : 0
));
const hasBenefit = computed(() => benefit.value > 0);

const price = computed(() => {
  let price = subscriptionPlan.value?.price[props.period] ?? 0;

  if (hasBenefit.value) {
    price *= 1 - (benefit.value / 100);
  }

  return price;
});

const nicePrice = computed(() => {
  if (price.value) {
    return price.value.toFixed(2).replace('.', ',').replace(',00', '');
  }

  return 0;
});

const yearlyPriceInfo = computed(() => {
  if (props.period === 'yearly') {
    let monthlyPrice = (subscriptionPlan.value?.price.monthly ?? 0) * 12;
    let yearlyPrice = (subscriptionPlan.value?.price.yearly ?? 0) * 12;

    if (hasBenefit.value) {
      monthlyPrice *= 1 - (benefit.value / 100);
      yearlyPrice *= 1 - (benefit.value / 100);
    }

    return {
      monthlyPrice: monthlyPrice.toFixed(2).replace('.', ',').replace(',00', ''),
      yearlyPrice: yearlyPrice.toFixed(2).replace('.', ',').replace(',00', ''),
    };
  }

  return null;
});

const { choosePlan, checkoutIsLoading } = useCheckout();
</script>

<template>
  <div class="subscription-plan-box">
    <AppCard v-if="subscriptionPlan">
      <template #title>
        {{ name }}
      </template>

      <template #default>
        <div class="description">
          {{ subscriptionPlan.description }}
        </div>

        <div
          v-if="subscriptionPlan.additionalInformation"
          class="additional-information">
          <div class="flex justify-center mt-4 mb-0">
            <button
              class="btn"
              @click.prevent="toggleAdditionalInformationVisibility">
              <template v-if="additionalInformationVisible">
                <font-awesome-icon
                  class="mr-2"
                  icon="circle-minus" /> {{ $t('SubscriptionPlanBox.hideMoreInfo') }}
              </template>
              <template v-else>
                <font-awesome-icon
                  class="mr-2"
                  icon="circle-plus" /> {{ $t('SubscriptionPlanBox.showMoreInfo') }}
              </template>
            </button>
          </div>

          <div
            v-if="additionalInformationVisible"
            class="my-3 text-left"
            v-html="subscriptionPlan.additionalInformation" />
        </div>
      </template>

      <template #footer>
        <div class="period">
          {{ $t(`SubscriptionPlans.monthly`).toLowerCase() }}
        </div>

        <div class="price">
          {{ `${nicePrice} €` }}
        </div>

        <div
          v-if="period === 'yearly' && yearlyPriceInfo"
          class="yearly-payment-info">
          <span>{{ yearlyPriceInfo.monthlyPrice }} €</span>
          <span class="ml-1">{{ yearlyPriceInfo.yearlyPrice }} €</span>
          {{ $t('SubscriptionPlans.perYear') }}
        </div>

        <div
          v-if="hasBenefit"
          class="benefit">
          ({{ $t('SubscriptionPlans.benefitInfo', { benefit, level: redeemedLevel }) }})
        </div>
      </template>
    </AppCard>

    <div
      class="mt-4 flex justify-center"
      v-if="showBuyCta">
      <BaseButton
        @click.prevent="choosePlan(code)"
        class="btn-primary"
        :loading="checkoutIsLoading">
        {{ $t('SubscriptionPlanBox.getCta') }}
      </BaseButton>
    </div>
  </div>
</template>

<style>
.subscription-plan-box {
  max-width: 400px;

  header {
    text-align: center;
    font-size: var(--font-size-xl);
  }

  .description {
    font-size: var(--font-size-m);
    line-height: 1.5;
    text-align: center;
  }

  .additional-information {
    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        font-size: var(--font-size-m);
        position: relative;
        padding: var(--space-2) 0 var(--space-2) var(--space-5);

        &:before {
          content: "";
          width: 20px;
          height: 20px;
          display: block;
          background: url('@/assets/img/icons/check-circle-regular--secondary.png') no-repeat;
          background-size: contain;
          position: absolute;
          top: 6px;
          left: 0;
        }
      }
    }
  }

  footer {
    text-align: center;

    .period, .benefit {
      font-size: var(--font-size-m);
      color: var(--color-muted);
    }

    .period {
      margin-bottom: var(--space-1);
    }

    .yearly-payment-info {
      color: var(--color-muted);
      margin-top: var(--space-1);
      font-size: var(--font-size-s);

      span {
        &:first-child {
          color: var(--color-danger);
          text-decoration: line-through;
        }
      }
    }

    .benefit {
      margin-top: var(--space-1);
    }

    .price {
      font-size: var(--font-size-2xl);
      font-weight: var(--font-weight-bold);
    }
  }
}
</style>
