import { BehaviorSubject, filter, take } from 'rxjs';
import { loadFbPixel } from './pixel-loader';

let initCalled = false;
let enabled = false;
export const canTrackFb$ = new BehaviorSubject(false);
let fbq: any = null;

export const trackFbEvent = async (name: string, eventParams?: Record<string, unknown>) => {
  if (enabled) {
    canTrackFb$.pipe(
      filter(Boolean),
      take(1),
    ).subscribe(async () => {
      fbq('track', name, eventParams);
    });
  }
};

export const enableFacebookPixel = async () => {
  if (import.meta.env.VITE_FB_PIXEL_ID) {
    enabled = true;

    if (!initCalled) {
      initCalled = true;
      await loadFbPixel();

      // @ts-ignore
      fbq = window.fbq;

      fbq('consent', 'grant');
      fbq('init', import.meta.env.VITE_FB_PIXEL_ID);
    } else if (fbq) {
      fbq('consent', 'grant');
    }

    trackFbEvent('PageView');

    canTrackFb$.next(true);
  }
};

export const disableFacebookPixel = async () => {
  if (enabled && fbq) {
    fbq('consent', 'revoke');
    enabled = false;
  }
};
