<script setup lang="ts">
import type { IconProp } from '@fortawesome/fontawesome-svg-core';
import { computed, useAttrs } from 'vue';

interface Props {
  loading?: boolean;
  progress?: number;
  icon?: IconProp;
  badge?: string | number;
}

const props = defineProps<Props>();

const attrs = useAttrs();
const attrsWithoutClass = computed(() => {
  const { class: _, ...rest } = attrs;

  return rest;
});

// @ts-ignore
const showProgress = computed(() => (typeof props.progress !== 'undefined' && props.loading));
</script>

<template>
  <button
    v-bind="attrsWithoutClass"
    :class="{ 'ld-ext-left running': loading, 'in-progress': showProgress }"
    :disabled="loading"
    class="btn">
    <span
      v-if="showProgress"
      class="progress">
      <span :style="{ width: `${progress}%` }" />
    </span>

    <font-awesome-icon
      v-if="icon"
      class="mr-2"
      :icon="icon" />

    <slot />
    <span
      v-if="loading"
      class="ld ld-ring ld-spin" />

    <span
      class="badge"
      v-if="badge">
      <span>{{ badge }}</span>
    </span>
  </button>
</template>
