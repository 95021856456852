import type { NeedsAnalysisQuestionCategory, UserProfileSettings } from './typings';

export const weekdays = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
] as const;

export const mealTypes = [
  'breakfast',
  'lunch',
  'dinner',
  'snack_a',
  'snack_b',
  'snack_c',
  'post_workout',
  'pre_workout',
  'drinking',
  'between_meal_a',
  'between_meal_b',
  'between_meal_c',
] as const;

export const systemMessageTypes = [
  'success',
  'error',
  'info',
  'warning',
] as const;

export const defaultUserProfileSettings = (locale: 'de' | 'en'): UserProfileSettings => {
  const palFactors = {
    job: {
      1: 0.95,
      2: 1.2,
      3: 1.4,
      4: 1.5,
      5: 1.6,
      6: 1.7,
      7: 1.8,
      8: 1.9,
      9: 2,
      10: 2.1,
      11: 2.2,
      12: 2.3,
      13: 2.4,
    },
    freeTime: {
      1: 1.2,
      2: 1.4,
      3: 1.6,
      4: 1.9,
      5: 2.2,
    },
  };

  const microNutrientReferenceValues = {
    calcium: 1000,
    kalium: 4000,
    sodium: 1500,
    magnesium: 350,
    chloride: 2300,
    phosphor: 700,
    iron: 15,
    iodine: 0.2,
    zinc: 14,
    vitA_Betacarotene: 10.2,
    vitB1: 1.2,
    vitB2: 1.4,
    vitB3: 15,
    vitB5: 6,
    vitB6: 1.6,
    vitB9: 0.3,
    vitB12: 0.004,
    vitC: 110,
    vitD: 0.02,
    vitE: 14,
  };

  const needsAnalysisQuestions = {
    health: [
      {
        de: 'Bestehen Unverträglichkeiten bzw. Allergien gegen spezielle Lebensmittel?',
        en: 'Are there any intolerances or allergies to specific foods?',
      },
      {
        de: 'Leidest du an Krankheiten? (Herzkreislaufbeschwerden, Allergien, Orthopädische Beschwerden o. Ä.)',
        en: 'Do you suffer from any diseases? (Cardiovascular problems, allergies, orthopedic complaints, etc.)',
      },
      {
        de: 'Nimmst du Medikamente ein?',
        en: 'Are you taking any medications?',
      },
      {
        de: 'Bekannte Krankheiten in der Familie? (Diabetes, Bluthochdruck, Alzheimer, Herzinfarkt)',
        en: 'Known diseases in the family? (Diabetes, high blood pressure, Alzheimer\'s disease, heart attack)',
      },
    ],
    eatingBehavior: [
      {
        de: 'Welche Lebensmittel magst du geschmacklich nicht?',
        en: 'What foods do you dislike in taste?',
      },
      {
        de: 'Auf welche Lebensmittel möchtest du ungern verzichten?',
        en: 'What foods would you hate to give up?',
      },
      {
        de: 'Welche Nahrungsmittel schmecken dir besonders gut?',
        en: 'Which foods do you like the most?',
      },
      {
        de: 'Zu welcher Tageszeit hast du am meisten Hunger und wann am wenigsten?',
        en: 'What time of day are you hungriest and when are you least hungry?',
      },
      {
        de: 'Leidest du an Heißhungerattacken? Wenn ja um Welche Tageszeit? Wie häufig treten diese auf?',
        en: 'Do you suffer from food cravings? If so, at what time of day? How often do they occur?',
      },
      {
        de: 'Wie viel Zeit nimmst du dir im Schnitt beim Essen?',
        en: 'On average, how much time do you take to eat?',
      },
      {
        de: 'Isst du alleine, mit Familie/Freunde/Kollegen?',
        en: 'Do you eat alone, with family/friends/colleagues?',
      },
      {
        de: 'Nimmst du Nahrungsergänzungen zu dir?',
        en: 'Do you take nutritional supplements?',
      },
      {
        de: 'Trinkst du Alkohol?',
        en: 'Do you drink alcohol?',
      },
      {
        de: 'Hast du in Vergangenheit schon einmal eine Diät versucht? Wenn ja welche und mit welchem Erfolg?',
        en: 'Have you ever tried a diet in the past? If so, which one and with what success?',
      },
    ],
    workout: [
      {
        de: 'Wie viele Wiederholungen machst du pro Muskelgr. pro Trainingseinheit?',
        en: 'How many reps do you do per muscle gr. per workout?',
      },
      {
        de: 'Wie oft trainierst du in der Woche?',
        en: 'How often do you train per week?',
      },
      {
        de: 'Welche Trainingsprogramme hast du in der Vergangenheit ausprobiert?',
        en: 'What training programs have you tried in the past?',
      },
      {
        de: 'Welche Übungen machst du für diverse Muskelgruppen?',
        en: 'What exercises do you do for various muscle groups?',
      },
      {
        de: 'Gehst du bis ans Muskelversagen beim Training?',
        en: 'Do you go to muscle failure when you work out?',
      },
    ],
    goals: [
      {
        de: 'Was erwartest du von der Ernährungsberatung?',
        en: 'What do you expect from nutrition counseling?',
      },
      {
        de: 'Was willst du genau erreichen und bis wann?',
        en: 'What exactly do you want to achieve and by when?',
      },
      {
        de: 'Was ist deine Motivation, um weiterzumachen und nicht aufzugeben?',
        en: 'What is your motivation to keep going and not give up?',
      },
    ],
  };

  const postingAccounts = [
    {
      number: 8000,
      name: {
        de: 'WhatsAppCoaching',
        en: 'WhatsAppCoaching',
      },
    },
    {
      number: 8001,
      name: {
        de: 'Training',
        en: 'Training',
      },
    },
    {
      number: 8002,
      name: {
        de: 'Ernährungspläne',
        en: 'Nutrition plans',
      },
    },
    {
      number: 8003,
      name: {
        de: 'Beratung',
        en: 'Consulting',
      },
    },
    {
      number: 8200,
      name: {
        de: 'Umsatzerlöse',
        en: 'Revenues',
      },
    },
    {
      number: 4210,
      name: {
        de: 'Miete',
        en: 'Rent',
      },
    },
    {
      number: 4360,
      name: {
        de: 'Versicherungen',
        en: 'Insurances',
      },
    },
    {
      number: 4380,
      name: {
        de: 'Beiträge',
        en: 'Contributions',
      },
    },
    {
      number: 4390,
      name: {
        de: 'Sonstige Abgaben',
        en: 'Other charges',
      },
    },
    {
      number: 4600,
      name: {
        de: 'Werbekosten',
        en: 'Advertising costs',
      },
    },
    {
      number: 4650,
      name: {
        de: 'Bewirtungskosten',
        en: 'Hospitality costs',
      },
    },
    {
      number: 4673,
      name: {
        de: 'Fahrtkosten',
        en: 'Travel costs',
      },
    },
    {
      number: 4910,
      name: {
        de: 'Porto',
        en: 'Petty cash',
      },
    },
    {
      number: 4920,
      name: {
        de: 'Telefon',
        en: 'Phone',
      },
    },
    {
      number: 4930,
      name: {
        de: 'Bürobedarf',
        en: 'Office supplies',
      },
    },
    {
      number: 4945,
      name: {
        de: 'Fortbildungskosten',
        en: 'Training costs',
      },
    },
    {
      number: 4950,
      name: {
        de: 'Rechts- und Beratungskosten',
        en: 'Legal and consulting fees',
      },
    },
    {
      number: 4955,
      name: {
        de: 'Buchführungskosten',
        en: 'Accounting costs',
      },
    },
    {
      number: 4980,
      name: {
        de: 'Sonstiger Aufwand',
        en: 'Other expenses',
      },
    },
  ];

  return {
    palFactors,
    microNutrientReferenceValues,
    needsAnalysisQuestions: Object.fromEntries(
      Object
        .keys(needsAnalysisQuestions)
        .map((key) => [
          key,
          needsAnalysisQuestions[key as keyof typeof needsAnalysisQuestions].map((r) => r[locale]),
        ]),
    ) as { [key in NeedsAnalysisQuestionCategory]: string[]; },
    accounting: {
      accounts: postingAccounts.map((r) => ({
        number: r.number,
        name: r.name[locale],
      })),
      invoice: {
        currency: 'EUR',
      },
    },
  };
};

export const transactionStates = ['open', 'paid'] as const;

// see https://app.clickup.com/t/86bwar7kx
export const defaultPlanIds = [
  'zyBOxQdRvqeyNaTA9vGL',
  'VnQlORiMVtnIDVFkw89x',
  'rp8hhmK7z9SEVuvQP0BT',
  'OYvepz3yZOonY2afjEjs',
  '2Etvp88ncbwWe66b7ssY',
];
