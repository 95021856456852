import { computed, readonly, ref } from 'vue';
import type {
  MaybeNull, SubscriptionPlan, SubscriptionPlanCode, SubscriptionPlanPeriod,
} from '@kcalc/lib/browser';
import { filter, lastValueFrom, take } from 'rxjs';
import { user$ } from '@/lib/firebase/auth';
import { startSubscriptionFlow$ } from '@/lib/firebase/functions';
import { useSystemMessage } from '@/composables/system-message';
import { useAuth } from '@/composables/firebase/auth';
import router from '@/router';
import { usePayment } from '@/composables/firebase/payment';
import { trackEvent } from '@/lib/analytics';
import { trackFbEvent } from '@/lib/facebook/analytics';

const subscriptionPlanCode = ref<MaybeNull<SubscriptionPlanCode>>(null);
const chosenPeriod = ref<SubscriptionPlanPeriod>('monthly');

const checkoutIsLoading = ref(false);

const { setSystemMessageByCode } = useSystemMessage();
const { subscriptionPlans, subscriptionPeriod } = usePayment();
const { user } = useAuth();

const chosenSubscriptionPlan = computed<MaybeNull<SubscriptionPlan>>(() => {
  if (subscriptionPlanCode.value && subscriptionPlans.value) {
    return subscriptionPlans.value.find((p) => p.code === subscriptionPlanCode.value) as SubscriptionPlan;
  }

  return null;
});

const hasRequiredPersonalData = computed(() => {
  if (user.value?.profile?.personalData) {
    return !!(user.value.profile.personalData.firstName
      && user.value.profile.personalData.surname
      && user.value.profile.personalData.country
      && user.value.profile.personalData.zipCode
      && user.value.profile.personalData.city
      && user.value.profile.personalData.street);
  }

  return false;
});

const persistPlan = () => {
  try {
    if (subscriptionPlanCode.value) {
      sessionStorage.setItem('kcalc-checkout-plan', JSON.stringify({
        code: subscriptionPlanCode.value,
        period: chosenPeriod.value,
      }));
    } else {
      sessionStorage.removeItem('kcalc-checkout-plan');
    }
  } catch (e) {
    // noop
  }
};

const loadPlan = () => {
  try {
    const sessionValue = sessionStorage.getItem('kcalc-checkout-plan');

    if (sessionValue) {
      try {
        const { code, period } = JSON.parse(sessionValue);
        subscriptionPlanCode.value = code as SubscriptionPlanCode;
        chosenPeriod.value = period as SubscriptionPlanPeriod;
      } catch (e) {
        // legacy handling, only plan code was stored - default to monthly period
        subscriptionPlanCode.value = sessionValue as SubscriptionPlanCode;
        chosenPeriod.value = 'monthly';
      }
    }
  } catch (e) {
    // noop
  }
};

const choosePlan = async (plan: SubscriptionPlanCode, period?: SubscriptionPlanPeriod, redirect = true) => {
  checkoutIsLoading.value = true;
  subscriptionPlanCode.value = plan;

  if (period) {
    chosenPeriod.value = period;
  } else {
    chosenPeriod.value = subscriptionPeriod.value;
  }

  persistPlan();

  if (redirect) {
    const to = hasRequiredPersonalData.value ? 'checkoutOverview' : 'checkoutPersonalData';

    router.push({ name: to });
    checkoutIsLoading.value = false;
  }
};

const buyPlan = async () => {
  if (!subscriptionPlanCode.value) {
    await router.push({ name: 'choosePlan' });
    return;
  }

  if (!hasRequiredPersonalData.value) {
    await router.push({ name: 'checkoutPersonalData' });
    return;
  }

  checkoutIsLoading.value = true;
  const user = await lastValueFrom(user$.pipe(
    filter(Boolean),
    take(1),
  ));

  if (!user) {
    router.push({ name: 'login' });
    checkoutIsLoading.value = false;
    return;
  }

  trackEvent('begin_checkout');
  trackFbEvent('InitiateCheckout');

  await lastValueFrom(startSubscriptionFlow$({
    plan: subscriptionPlanCode.value,
    period: chosenPeriod.value,
  })).then((response) => {
    location.href = response;

    checkoutIsLoading.value = false;
  }).catch((e) => {
    setSystemMessageByCode(e.message);
    checkoutIsLoading.value = false;
  });
};

loadPlan();

export function useCheckout() {
  return {
    subscriptionPlanCode: readonly(subscriptionPlanCode),
    chosenPeriod: readonly(chosenPeriod),
    chosenSubscriptionPlan,
    choosePlan,
    checkoutIsLoading: readonly(checkoutIsLoading),
    buyPlan,
    hasRequiredPersonalData,
  };
}
