import type { Directive } from 'vue';
import { Subject } from 'rxjs';

const newsTrigger = new Subject<string>();
export const newsTrigger$ = newsTrigger.asObservable();

function handleClick(newsId: string) {
  newsTrigger.next(newsId);
}

export const vNewsTrigger: Directive<HTMLElement> = {
  mounted(el, { value }) {
    el.addEventListener('click', () => handleClick(value));
  },
  unmounted(el) {
    el.removeEventListener('click', () => handleClick);
  },
};
