<script setup lang="ts">
import { computed, watch } from 'vue';
import { useRoute } from 'vue-router';
import BaseModal from '@/components/BaseModal.vue';
import { useAuth } from '@/composables/firebase/auth';
import { usePayment } from '@/composables/firebase/payment';
import SubscriptionPlans from '@/components/payment/SubscriptionPlans.vue';
import SuspendedSubscription from '@/components/payment/SuspendedSubscription.vue';
import { isPlatform } from '@/lib/platform';

const route = useRoute();
const { user, hasActiveTrial, hasAccess } = useAuth();
const { subscription, previousSubscription } = usePayment();

const trialExpired = computed(() => (
  user.value
  && !hasActiveTrial.value
  && !user.value?.profile?.subscription
));

const showPaywall = computed(() => user.value && user.value.profile?.verifiedAt && !hasAccess.value && !route.meta.bypassAccessControl);

const platformHasTrial = !isPlatform('otl');

const applyAppStyles = () => {
  const el = document.getElementById('app');

  if (el) {
    el.style.setProperty('filter', 'blur(4px)', 'important');
    el.style.setProperty('pointer-events', 'none', 'important');
  }
};

const clearAppStyles = () => {
  const el = document.getElementById('app');

  if (el) {
    el.style.filter = '';
    el.style.pointerEvents = '';
  }
};

let elementObserver: MutationObserver;
let appObserver: MutationObserver;
watch(showPaywall, () => {
  if (showPaywall.value) {
    applyAppStyles();

    if (!elementObserver) {
      // Set up a mutation observer, watching if the paywall gets removed - just re-append it if so
      elementObserver = new MutationObserver((records) => {
        records.forEach(({ removedNodes }) => {
          const element = Array.prototype.find.call(removedNodes, ({ id }) => id === 'app-paywall');
          if (element && !document.getElementById('app-paywall')) {
            document.body.appendChild(element);
          }
        });
      });

      elementObserver.observe(document.body, {
        subtree: true,
        childList: true,
      });
    }

    if (!appObserver) {
      appObserver = new MutationObserver(() => {
        applyAppStyles();
      });

      appObserver.observe(document.getElementById('app') as HTMLElement, {
        attributes: true,
        attributeFilter: ['style'],
      });
    }
  } else {
    if (elementObserver) {
      elementObserver.disconnect();
    }

    if (appObserver) {
      appObserver.disconnect();
    }

    clearAppStyles();
  }
});
</script>

<template>
  <BaseModal
    id="app-paywall"
    size="xl"
    v-if="showPaywall"
    :show-close-action="false"
    :can-close="false"
    :title="$t('AppPaywall.title')">
    <template #default>
      <SuspendedSubscription
        v-if="previousSubscription && previousSubscription.status === 'suspended'" />

      <div
        v-else
        class="text-center">
        <p
          v-if="trialExpired"
          class="line-height-m"
          v-html="$t(platformHasTrial ? 'AppPaywall.trialExpired' : 'AppPaywall.noTrial')" />

        <p
          v-if="!subscription && !trialExpired"
          class="line-height-m"
          v-html="$t('AppPaywall.noSubscription')" />

        <p class="mt-1">
          <RouterLink
            class="cta cta-secondary cta--small"
            :to="{ name: 'profile' }">
            {{ $t('AppPaywall.manageAccountCta') }}
          </RouterLink>
        </p>

        <SubscriptionPlans
          class="mt-5" />
      </div>
    </template>
  </BaseModal>
</template>
