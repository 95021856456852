import { initializeApp } from 'firebase/app';
import { connectFirestoreEmulator, getFirestore } from 'firebase/firestore';
import { connectAuthEmulator, getAuth } from 'firebase/auth';
import { connectStorageEmulator, getStorage } from 'firebase/storage';
import { connectFunctionsEmulator, getFunctions } from 'firebase/functions';

const region = 'europe-west3';
const app = initializeApp({
  appId: import.meta.env.VITE_FIREBASE_APP_ID,
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
  messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
  storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
});
console.log(`Initialized Firebase app (${app.name}:${app.options.projectId}@${region})`);

export const db = getFirestore(app);
export const auth = getAuth(app);
export const storage = getStorage(app);
export const functions = getFunctions(app, region);

if (import.meta.env.DEV) {
  connectFirestoreEmulator(db, location.hostname, 8484);
  connectAuthEmulator(auth, `http://${location.hostname}:9099`);
  connectStorageEmulator(storage, location.hostname, 9199);
  connectFunctionsEmulator(functions, location.hostname, 5001);
}
