<!-- eslint-disable -->
<template>
  <svg
    class="kc-icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 250 250">
    <g>
      <path
        d="M125,79.98c-27.09-.19-25.11-4.98-39.5-4.98-39.24,0-48.96,34.22-48.96,63.3,0,33.51,24.3,73.45,52.63,81.44,6.44,1.81,13.77-1.42,21.35-4.42,9.29-3.67,19.65-3.67,28.94,0,7.58,3,14.92,6.23,21.35,4.42,28.34-7.99,52.63-47.93,52.63-81.44,0-29.08-9.72-63.3-48.96-63.3-14.4,0-12.42,4.8-39.5,4.98" />
      <path
        d="M125,79.98c-.54,0-1.06,0-1.62,0h3.24c-.56,0-1.09,0-1.62,0" />
    </g>
    <path
      d="M118.95,75v-25c0-13.81,11.19-25,25-25v25c0,13.81-11.19,25-25,25Z" />
  </svg>
</template>
