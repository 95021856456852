import type { GenericObject } from './typings';

export const roundNumber = (value: number, decimals = 2) => {
  const power = 10 ** decimals;

  return Math.round((value + Number.EPSILON) * power) / power;
};

export const roundAndFormatNumber = (value: number, decimals = 2) => {
  const val = roundNumber(value, decimals).toString();

  return decimals > 0
    ? val.replace('.', ',')
    : val;
};

/**
 * Thanks, ChatGPT
 */
export function generateRandomString(length: number, config?: { includeSpecialChars: boolean }) {
  const {
    includeSpecialChars = true,
  } = config || {};

  const chars = [
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789',
    includeSpecialChars ? '!@#$%^&*()_-+[]{}|;:,.<>?' : '',
  ].join('');

  const randomStrParts = [];
  const randomBuffer = new Uint8Array(length);

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < randomBuffer.length; i++) {
    randomBuffer[i] = Math.floor(Math.random() * 256);
  }

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < length; i++) {
    randomStrParts.push(chars[randomBuffer[i] % chars.length]);
  }

  return randomStrParts.join('');
}

export function removeUndefined<T extends Record<string, any>>(obj: T): Partial<T> {
  const newObj: any = {};
  Object.keys(obj).forEach((key) => {
    if (obj[key] === Object(obj[key])) newObj[key] = removeUndefined(obj[key]);
    else if (obj[key] !== undefined) newObj[key] = obj[key];
  });
  return newObj as Partial<T>;
}

export const generateUUID = (): string =>
  // @ts-ignore
  // eslint-disable-next-line no-bitwise,implicit-arrow-linebreak
  ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) => (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16));

const arrayCompareFunction = <T extends GenericObject, K extends keyof T>(key: K, order: 'asc' | 'desc' = 'asc') => (a: T, b: T) => {
  if (typeof key !== 'string') {
    return 0;
  }

  let varA: unknown = key.split('.').reduce((o, i) => (o)[i], a);
  if (typeof varA === 'string') varA = varA.toUpperCase();

  let varB: unknown = key.split('.').reduce((o, i) => o[i], b);
  if (typeof varB === 'string') varB = varB.toUpperCase();

  if (!varA && !varB) return 0;

  if (typeof varA === 'string' && typeof varB === 'string') {
    const stringComparison = Intl.Collator().compare(varA, varB);

    return order === 'desc' ? stringComparison * -1 : stringComparison;
  }

  if ((typeof varA === 'number' && typeof varB === 'number')) {
    let comparison = 0;

    if (!varA) comparison = -1;
    if (!varB) comparison = 1;

    if (varA > varB) comparison = 1;
    else if (varA < varB) comparison = -1;

    return order === 'desc' ? comparison * -1 : comparison;
  }

  return 0;
};

export function sortArray<T extends GenericObject[]>(array: T, key: string, order: 'asc' | 'desc' = 'asc'): T {
  return array.sort(arrayCompareFunction(key, order));
}
