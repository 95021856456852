<!-- eslint-disable -->
<template>
  <svg
    class="kc-icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 250 250">
    <path
      d="M187.5,25v25h0c0,20.71-16.79,37.5-37.5,37.5h-25v-25h0c0-20.71,16.79-37.5,37.5-37.5h25Z" />
    <path
      d="M75,75h20c16.57,0,30,13.43,30,30h0v20h-20c-16.57,0-30-13.43-30-30h0v-20Z" />
    <line
      class="cls-1"
      x1="225"
      y1="150"
      x2="25"
      y2="150" />
    <line
      x1="25"
      y1="225"
      x2="225"
      y2="225" />
    <path
      d="M87.5,187.5h25s0,0,0,0c6.9,0,12.5-5.6,12.5-12.5h0V87.5" />
    <path
      d="M125,175v12.5s0,0,0,0c0,6.9,5.6,12.5,12.5,12.5h12.5s12.5,0,12.5,0" />
    <line
      x1="175"
      y1="175"
      x2="200"
      y2="175" />
    <line
      x1="37.5"
      y1="175"
      x2="62.5"
      y2="175" />
  </svg>
</template>
