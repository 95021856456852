<!-- eslint-disable -->
<template>
  <svg
    class="kc-icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 250 250">
    <rect
      x="25"
      y="25"
      width="200"
      height="200" />
    <polyline
      points="125 25 125 105 125 225" />
    <line
      x1="225"
      y1="100"
      x2="125"
      y2="100" />
    <line
      x1="125"
      y1="150"
      x2="25"
      y2="150" />
  </svg>
</template>
