import type { GenericObject } from '@kcalc/lib/browser';

const arrayCompareFunction = <T extends GenericObject, K extends keyof T>(key: K, order: 'asc' | 'desc' = 'asc') => (a: T, b: T) => {
  if (typeof key !== 'string') {
    return 0;
  }

  let varA: unknown = key.split('.').reduce((o, i) => (o)[i], a);
  if (typeof varA === 'string') varA = varA.toUpperCase();

  let varB: unknown = key.split('.').reduce((o, i) => o[i], b);
  if (typeof varB === 'string') varB = varB.toUpperCase();

  if (!varA && !varB) return 0;

  if (typeof varA === 'string' && typeof varB === 'string') {
    const stringComparison = Intl.Collator().compare(varA, varB);

    return order === 'desc' ? stringComparison * -1 : stringComparison;
  }

  if ((typeof varA === 'number' && typeof varB === 'number')) {
    let comparison = 0;

    if (!varA) comparison = -1;
    if (!varB) comparison = 1;

    if (varA > varB) comparison = 1;
    else if (varA < varB) comparison = -1;

    return order === 'desc' ? comparison * -1 : comparison;
  }

  return 0;
};

export function sortArray<T extends GenericObject[]>(array: T, key: string, order: 'asc' | 'desc' = 'asc'): T {
  return array.sort(arrayCompareFunction(key, order));
}

interface StatedHTMLElement extends HTMLElement {
  ready?: boolean;
}

export function watchElement(selector: string, fn: (el: StatedHTMLElement) => void) {
  let listeners: {
    selector: string;
    fn: (el: StatedHTMLElement) => void;
  }[] = [];
  const { document: doc, MutationObserver } = window;

  const check = () => {
    for (let i = 0, len = listeners.length, listener, elements; i < len; i += 1) {
      listener = listeners[i];

      // Query for elements matching the specified selector
      elements = doc.querySelectorAll<StatedHTMLElement>(listener.selector);
      for (let j = 0, jLen = elements.length, element; j < jLen; j += 1) {
        element = elements[j];

        // Make sure the callback isn't invoked with the same element more than once
        if (!element.ready) {
          element.ready = true;

          // Invoke the callback with the element
          listener.fn.call(element, element);
        }
      }
    }
  };

  let observer;
  if (!observer) {
    observer = new MutationObserver(check);
    observer.observe(doc.documentElement, {
      childList: true,
      subtree: true,
    });
  }

  // Register listener for elements targeted by selector param
  listeners = [
    ...listeners,
    {
      selector,
      fn,
    },
  ];

  // Trigger first round of checking
  check();
}

const isObject = (item: unknown): boolean => !!(item && typeof item === 'object' && !Array.isArray(item));

/*
 * Deep merge given objects. Thanks to
 * https://stackoverflow.com/a/34749873 and https://stackoverflow.com/a/37164538
 */
export const mergeDeep = (target: GenericObject, ...sources: GenericObject[]): GenericObject => {
  if (!sources.length) {
    return target;
  }

  const output = { ...target };
  const source = sources.shift() as GenericObject;
  if (isObject(target) && isObject(source)) {
    Object.keys(source).forEach((key) => {
      if (isObject(source[key])) {
        if (!(key in target)) Object.assign(output, { [key]: source[key] });
        else output[key] = mergeDeep(target[key], source[key]);
      } else {
        Object.assign(output, { [key]: source[key] });
      }
    });
  }
  return mergeDeep(output, ...sources);
};

// https://bit.ly/2neWfJ2
export const chunk = (arr: any[], size: number) => (
  Array.from({ length: Math.ceil(arr.length / size) }, (v, i) => arr.slice(i * size, i * size + size))
);

export const cloneObject = <T>(obj: T): T => JSON.parse(JSON.stringify(obj));

export function removeUndefined<T>(obj: Record<string, unknown>) {
  return Object.entries(obj).reduce<T>(
    (a, [k, v]) => {
      if (v === undefined) {
        return a;
      }

      return {
        ...a,
        [k]: v,
      };
    },
    {} as T,
  );
}

export function undefine(property: unknown): void {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars,no-unused-vars
  property = undefined;
}

export function pxToMm(value: number, scale = 1): number {
  return (value * 0.2645833333) / scale;
}

export function mmToPx(value: number, scale = 1): number {
  return (value / 0.2645833333) * scale;
}

export function sleep(millis: number): Promise<void> {
  return new Promise((resolve) => {
    setTimeout(() => resolve(), millis);
  });
}
