import { ref } from 'vue';
import type { MaybeUndefined, NewsEntry } from '@kcalc/lib/browser';
import { fetchNews } from '@/lib/backend';

const newsFetched = ref(false);
const newsEntries = ref<MaybeUndefined<NewsEntry[]>>();

fetchNews().then((results) => {
  newsEntries.value = results;
  newsFetched.value = true;
});

export function useNews() {
  return {
    newsEntries,
    newsFetched,
  };
}
