import type {
  UserLevel, SubscriptionPlan, UserProfile, SubscriptionPlanPeriod,
} from './typings';

export const subscriptionBenefitPercentage: {
  [key in UserLevel]: number
} = {
  0: 0,
  1: 5,
  2: 7,
  3: 10,
};

export const getPlanPriceForUser = (
  subscriptionPlan: SubscriptionPlan,
  period: SubscriptionPlanPeriod,
  userProfile: UserProfile,
  applyBenefits = true,
  applyTax = true,
  pricePerYear = false,
) => {
  // get base price
  let price = subscriptionPlan.price[period];

  if (period === 'yearly' && pricePerYear) {
    price *= 12;
  }

  // apply level benefits
  if (
    applyBenefits
    && userProfile.gamification?.redeemedLevel
    && userProfile.gamification.redeemedLevel > 0
    && subscriptionBenefitPercentage?.[userProfile.gamification.redeemedLevel]
  ) {
    price *= 1 - (subscriptionBenefitPercentage[userProfile.gamification.redeemedLevel] / 100);
  }

  if (applyTax) {
    // apply tax
    price *= 1.19;
  }

  return price;
};
