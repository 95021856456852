<!-- eslint-disable -->
<template>
  <svg
    class="kc-icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 250 250">
    <line
      x1="25"
      y1="225"
      x2="225"
      y2="225" />
    <polyline
      points="125 75 125 120 125 156.82 125 225" />
    <path
      d="M162.5,175v12.5h0c0,6.9-5.6,12.5-12.5,12.5h0s-25,0-25,0" />
    <path
      d="M87.5,175h0c0,6.9,5.6,12.5,12.5,12.5h0s25,0,25,0" />
    <circle
      cx="125"
      cy="87.5"
      r="62.5" />
    <path
      d="M187.5,150v10h0c0,8.28-6.72,15-15,15h-10v-10h0c0-8.28,6.72-15,15-15h10Z" />
    <path
      d="M62.5,150v10s0,0,0,0c0,8.28,6.72,15,15,15h10s0-10,0-10h0c0-8.28-6.72-15-15-15h-10Z" />
    <path
      d="M125,100h-12.5c-6.9,0-12.5-5.6-12.5-12.5h0" />
    <path
      d="M150,100v12.5c0,6.9-5.6,12.5-12.5,12.5h-12.5" />
  </svg>
</template>
