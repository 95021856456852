import type { MaybeUndefined } from '@kcalc/lib/browser';

type Provider = 'sgd';
type CredentialType = 'access_token' | 'refresh_token';
type Credential = Record<CredentialType, MaybeUndefined<string>> | string;

const store: Partial<Record<Provider, Credential>> = {};

export function useCredentials() {
  function setCredential(provider: Provider, credential: Credential) {
    store[provider] = credential;
  }

  function getCredential(provider: Provider): MaybeUndefined<Credential> {
    const credential = structuredClone(store[provider]);
    delete store[provider];

    return credential;
  }

  return {
    setCredential,
    getCredential,
  };
}
