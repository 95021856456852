<!-- eslint-disable -->
<template>
  <svg
    class="kc-icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 250 250">
    <line
      x1="25"
      y1="225"
      x2="225"
      y2="225" />
    <path
      d="M162.5,225h0c-13.81,0-25-11.19-25-25h0v-37.5" />
    <path
      d="M87.5,225h0c13.81,0,25-11.19,25-25h0v-37.5" />
    <path
      d="M137.5,187.5h12.69c6.9,0,12.5-5.6,12.5-12.5h0v-12.5" />
    <path
      d="M112.5,187.5h-25c-6.9,0-12.5-5.6-12.5-12.5h0v-12.5" />
    <circle
      cx="162.5"
      cy="100"
      r="12.5" />
    <circle
      cx="50"
      cy="212.5"
      r="12.5" />
    <line
      x1="50"
      y1="187.5"
      x2="50"
      y2="200" />
    <circle
      cx="112.5"
      cy="62.5"
      r="12.5" />
    <circle
      cx="87.5"
      cy="125"
      r="12.5" />
    <path
      d="M200,112.5c-.36,0-.71,.01-1.06,.03,.69-4.08,1.06-8.26,1.06-12.53,0-41.42-33.58-75-75-75S50,58.58,50,100c0,4.27,.38,8.45,1.06,12.53-.35-.01-.71-.03-1.06-.03-13.81,0-25,11.19-25,25s11.19,25,25,25H200c13.81,0,25-11.19,25-25s-11.19-25-25-25Z" />
  </svg>
</template>
