import { readonly, ref } from 'vue';
import type { MaybeUndefined } from '@kcalc/lib/browser';

const isVisible = ref(false);
const messageRef = ref<MaybeUndefined<string>>();

/**
 *  message   Text to show within the loader overlay
 *  blur      Whether to reset user focus of the current element
 *  opaque    Whether the backdrop should be opaque (default: translucent)
 */
interface ShowLoaderOptions {
  message?: string;
  blur?: boolean;
  opaque?: boolean;
}

/**
 * @param options
 */
const showLoader = (options?: ShowLoaderOptions) => {
  isVisible.value = true;
  document.documentElement.classList.add('app--loading');
  if (options?.opaque) {
    document.documentElement.classList.add('app--loading-opaque');
  }
  messageRef.value = options?.message;

  if (options?.blur) {
    setTimeout(() => {
      const tmp = document.createElement('input');
      document.body.appendChild(tmp);
      tmp.focus();
      document.body.removeChild(tmp);
    }, 500);
  }
};

const hideLoader = () => {
  isVisible.value = false;
  document.documentElement.classList.remove(
    'app--loading',
    'app--loading-opaque',
  );
  messageRef.value = undefined;
};

const toggleLoader = () => {
  if (isVisible.value) {
    hideLoader();
  } else {
    showLoader();
  }
};

const setLoaderMessage = (message?: string) => {
  messageRef.value = message;
};

export function useLoader() {
  if (!isVisible.value && document.documentElement.classList.contains('app--loading')) {
    hideLoader();
  }

  return {
    isVisible: readonly(isVisible),
    message: readonly(messageRef),
    showLoader,
    hideLoader,
    toggleLoader,
    setLoaderMessage,
  };
}
