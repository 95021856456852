import { generateUUID } from '@kcalc/lib/browser';
import { computed, ref } from 'vue';

const overlayStack = ref<string[]>([]);
const topmostOverlay = computed(() => (
  overlayStack.value.length > 0
    ? overlayStack.value[overlayStack.value.length - 1]
    : undefined
));

const addToStack = (uuid: string) => {
  overlayStack.value.push(uuid);
};

const removeFromStack = (uuid: string) => {
  const index = overlayStack.value.findIndex((entry) => entry === uuid);

  if (index > -1) {
    overlayStack.value.splice(index, 1);
  }
};

export function useOverlayStack() {
  const uuid = generateUUID();

  const isTopmostOverlay = computed(() => uuid === topmostOverlay.value);

  return {
    overlayStack,
    addToStack: () => addToStack(uuid),
    removeFromStack: () => removeFromStack(uuid),
    uuid,
    isTopmostOverlay,
  };
}
