<script setup lang="ts">
import { RouterView } from 'vue-router';
import { useAuth } from '@/composables/firebase/auth';
import AppSidebar from '@/components/generic/AppSidebar.vue';
import BrandIcon from '@/components/brand/BrandIcon.vue';
import AppPaywall from '@/components/payment/AppPaywall.vue';
import SystemMessage from '@/components/generic/SystemMessage.vue';
import LevelUpNotification from '@/components/gamification/LevelUpNotification.vue';
import AppLoader from '@/components/generic/AppLoader.vue';
import { watchElement } from '@/lib/util';
import NewsNotification from '@/components/profile/NewsNotification.vue';

const { isLoading, isVerified, isAuthenticated } = useAuth();

watchElement('.ais-SearchBox-form input[type=search]', (el) => el.classList.add('field', 'text-m'));
</script>

<template>
  <div
    v-if="isLoading"
    class="loader">
    <brand-icon class="mb-5" />

    <font-awesome-icon
      size="lg"
      icon="circle-notch"
      spin />
  </div>

  <template v-else>
    <AppSidebar v-if="isVerified" />
    <RouterView />
    <SystemMessage />
    <AppLoader />
  </template>

  <AppPaywall />

  <LevelUpNotification />

  <NewsNotification v-if="isAuthenticated" />
</template>

<style>
main {
  flex: 1 1 auto;
  padding: var(--space-3) var(--space-3) 0;
  overflow-y: auto;
  max-height: 100%;
}
</style>
