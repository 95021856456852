<script setup lang="ts">
import { computed, ref } from 'vue';
import { DateTime } from 'luxon';
import { useToast } from 'vue-toastification';
import { useI18n } from 'vue-i18n';
import { usePayment } from '@/composables/firebase/payment';
import BaseButton from '@/components/BaseButton.vue';
import { apiClient } from '@/lib/api';

const { subscription: activeSubscription, previousSubscription } = usePayment();
const toast = useToast();
const { t } = useI18n();

const subscription = computed(() => activeSubscription.value || previousSubscription.value);

const paymentMethodChangeRunning = ref(false);
const changePaymentMethod = async () => {
  paymentMethodChangeRunning.value = true;

  const { data, error } = await apiClient.consultant.payment.mandate.create.post();

  if (error) {
    toast.error(
      typeof (error.value as any).message === 'string'
        ? (error.value as any).message
        : t('generic.errors.generic'),
    );
    paymentMethodChangeRunning.value = false;
  } else if (data?.redirect) {
    location.href = data.redirect;
    paymentMethodChangeRunning.value = false;
  }
};

const gracePeriodEnd = computed(() => (
  subscription.value?.suspendedAt
    ? DateTime.fromISO(subscription.value.suspendedAt).plus({ days: 7 }).toFormat('dd.MM.yyyy, HH:mm')
    : null
));
</script>

<template>
  <div v-if="subscription && subscription.status === 'suspended'">
    <p
      v-if="activeSubscription"
      v-html="$t('SuspendedSubscription.description.gracePeriod', { gracePeriodEnd })" />
    <p
      v-else
      v-html="$t('SuspendedSubscription.description.afterGracePeriod', { gracePeriodEnd })" />
    <p class="mt-3">
      <BaseButton
        class="btn-primary"
        @click="changePaymentMethod"
        :loading="paymentMethodChangeRunning"
        :icon="['far', 'credit-card']">
        {{ $t('SuspendedSubscription.cta') }}
      </BaseButton>
    </p>
  </div>
</template>
