import type { OnboardingStep, OnboardingStepState } from '@kcalc/lib/browser';
import { take, filter, lastValueFrom } from 'rxjs';
import { updateConsultant } from '@/lib/firebase/store';
import { user$ } from '@/lib/firebase/auth';

export const updateStep = async (userId: string, step: OnboardingStep, state: OnboardingStepState) => {
  const user = await lastValueFrom(user$.pipe(filter(Boolean), take(1)));

  // Trigger firebase update only if necessary / actually changed
  if (user.profile?.onboardingSteps?.[step] !== state) {
    await updateConsultant(userId, {
      onboardingSteps: {
        [step]: state,
      },
    });
  }
};

export const completeStep = async (userId: string, step: OnboardingStep) => {
  await updateStep(userId, step, true);
};

export const skipStep = async (userId: string, step: OnboardingStep) => {
  await updateStep(userId, step, 'skipped');
};
