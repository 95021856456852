import { httpsCallable } from 'rxfire/functions';
import type {
  BootstrapUserPayload,
  Credentials,
  GetUpgradeInfoPayload,
  SubscriptionFlowSetupPayload,
  SubscriptionUpgradePayload,
  UpgradeInfoResponse,
  UserLevel,
  VerificationEmailPayload,
  SgdTokens,
} from '@kcalc/lib/browser';
import { map, shareReplay } from 'rxjs';
import type { UserManagerSettings } from 'oidc-client-ts';
import { functions } from '@/lib/firebase/app';
import { platformCode } from '@/lib/platform';
import { useCredentials } from '@/composables/credentials';

const { getCredential } = useCredentials();

export const getCredentialsForUser$ = (userId: string) => {
  const callable = httpsCallable<{ userId: string, credentials?: Partial<Credentials> }, Credentials>(functions, 'getCredentialsForUser');

  return callable({
    userId,
    credentials: (() => {
      const localCredentials: Partial<Credentials> = {};

      const sgdCredentials = getCredential('sgd');
      if (sgdCredentials) {
        localCredentials.sgdTokens = sgdCredentials as SgdTokens;
      }

      return localCredentials;
    })(),
  });
};

export const sendVerificationEmail$ = (continueUrlPayload: VerificationEmailPayload) => (
  httpsCallable<VerificationEmailPayload, void>(functions, 'sendVerificationEmail')(continueUrlPayload)
);

export const startSubscriptionFlow$ = (payload: SubscriptionFlowSetupPayload) => (
  httpsCallable<SubscriptionFlowSetupPayload, string>(functions, 'startSubscriptionFlow')(payload)
);

export const cancelSubscription$ = () => httpsCallable<void, void>(functions, 'cancelSubscription')();

export const redeemLevelBenefits$ = (forLevel: UserLevel) => httpsCallable<UserLevel, void>(functions, 'redeemLevelBenefits')(forLevel);

export const upgradeSubscription$ = (payload: SubscriptionUpgradePayload) => (
  httpsCallable<SubscriptionUpgradePayload, string|undefined>(functions, 'upgradeSubscription')(payload)
);

export const getUpgradeInfo$ = (payload: GetUpgradeInfoPayload) => (
  httpsCallable<GetUpgradeInfoPayload, UpgradeInfoResponse>(functions, 'getUpgradeInfo')(payload)
);

export const getPlatformsForEmail$ = (email: string) => httpsCallable<string, string[]>(functions, 'getPlatformsForEmail')(email)
  .pipe(
    map((platforms) => platforms.filter((platform) => platform !== platformCode)),
  );

export const bootstrapUser$ = (payload: BootstrapUserPayload) => httpsCallable<BootstrapUserPayload, void>(functions, 'bootstrapUser')(payload);

export const getSgdOidcSettings$ = () => httpsCallable<
  void,
  Pick<UserManagerSettings, 'client_id' | 'client_secret' | 'authority'>
>(functions, 'getSgdOidcSettings')().pipe(
  shareReplay({ bufferSize: 1, refCount: true }),
);

export const setCRMAttributes$ = (attributes: Record<string, string>) => (
  httpsCallable<Record<string, string>, void>(functions, 'setCRMAttributes')(attributes)
);
