import type { GtagEvent } from 'vue-gtag';
import {
  bootstrap, event, optIn, optOut,
} from 'vue-gtag';
import { BehaviorSubject, filter, take } from 'rxjs';
import { disableFacebookPixel, enableFacebookPixel } from '@/lib/facebook/analytics';

let initCalled = false;
let enabled = false;
export const canTrack$ = new BehaviorSubject(false);

export const enableAnalytics = async () => {
  if (import.meta.env.VITE_GOOGLE_ANALYTICS_ID) {
    enabled = true;

    if (!initCalled) {
      initCalled = true;
      await bootstrap();
    }

    await optIn();
    canTrack$.next(true);
  }

  await enableFacebookPixel();
};

export const disableAnalytics = async () => {
  if (enabled) {
    enabled = false;
    optOut();
  }

  await disableFacebookPixel();
};

export const trackEvent: GtagEvent = async (action, eventParams?) => {
  if (enabled) {
    canTrack$.pipe(
      filter(Boolean),
      take(1),
    ).subscribe(async () => {
      await event(action, eventParams);
    });
  }
};
