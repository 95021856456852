<script setup lang="ts">
import { useLoader } from '@/composables/loader';
import BrandIcon from '@/components/brand/BrandIcon.vue';

const {
  isVisible, message,
} = useLoader();
</script>

<template>
  <teleport
    to="body">
    <Transition name="app-loader">
      <div
        class="app-loader"
        v-if="isVisible">
        <div>
          <BrandIcon class="mb-5" />

          <font-awesome-icon
            size="lg"
            icon="circle-notch"
            spin />

          <div
            v-if="message"
            class="mt-2 text-m">
            {{ message }}
          </div>
        </div>
      </div>
    </Transition>
  </teleport>
</template>

<style>
.app-loader {
  position: fixed;
  z-index: 10990;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.6);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
  transition: all 300ms ease;
  opacity: 1;

  > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    transform: translateY(-15%);
    color: var(--color-primary);
    transition: all 300ms ease;
  }

  .brand-icon {
    width: 80px;
    height: 80px;
  }
}

#app {
  transition: filter 300ms ease;
}

html.app--loading {
  #app {
    filter: blur(2px);
    pointer-events: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
}

html.app--loading-opaque .app-loader {
  background-color: var(--color-primary-contrast);
}

.app-loader-enter-from {
  opacity: 0;

  > div {
    transform: scale(1.2);
  }
}

.app-loader-leave-to {
  opacity: 0;

  > div {
    transform: scale(.8);
  }
}
</style>
