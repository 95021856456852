<script setup lang="ts">
import { onClickOutside, onKeyStroke, useDebounceFn } from '@vueuse/core';
import {
  computed, nextTick, onBeforeUnmount, onMounted, ref, useSlots,
} from 'vue';
import type { MaybeNull } from '@kcalc/lib/browser';
import { useOverlayStack } from '@/composables/overlay-stack';

interface Props {
  backdropDismiss?: boolean,
  closeOnEscape?: boolean,
  showCloseAction?: boolean,
  canClose?: boolean,
  focusFirstInput?: boolean,
  size?: 'sm' | 'lg' | 'xl' | '2xl', // implicit default is "medium"
  id?: string,
  translucentHeader?: boolean,
}

const props = withDefaults(defineProps<Props>(), {
  backdropDismiss: true,
  closeOnEscape: true,
  showCloseAction: true,
  canClose: true,
  focusFirstInput: false,
  size: undefined,
  id: undefined,
  translucentHeader: false,
});

const emit = defineEmits(['close', 'show', 'open']);
const slots = useSlots();
const clickTarget = ref<MaybeNull<HTMLElement>>(null);
const modalBody = ref<MaybeNull<HTMLElement>>(null);
const show = ref(false);
const transitionDuration = 300;
const {
  addToStack, removeFromStack, isTopmostOverlay,
} = useOverlayStack();

const scrollElHasOverflow = ref(false);
const checkContainerOverflow = useDebounceFn(() => {
  const scrollEl: MaybeNull<HTMLElement> = props.translucentHeader ? clickTarget.value : modalBody.value;
  scrollElHasOverflow.value = (scrollEl && scrollEl.scrollHeight > scrollEl.clientHeight) || false;
}, 100);

const onOpen = () => {
  nextTick(() => {
    emit('show');

    if (props.focusFirstInput && modalBody.value) {
      const inputs = modalBody.value.querySelectorAll('input,textarea');
      if (inputs && inputs.length > 0) {
        (inputs[0] as HTMLInputElement).focus();
      }
    }
  });
};

onMounted(() => {
  show.value = true;

  checkContainerOverflow();
  addToStack();
  emit('open');

  setTimeout(() => {
    onOpen();
  }, transitionDuration);
});

onBeforeUnmount(() => {
  removeFromStack();
});

function close() {
  if (props.canClose) {
    show.value = false;
    removeFromStack();
    setTimeout(() => {
      emit('close');
    }, transitionDuration);
  }
}

onKeyStroke('Escape', (e) => {
  if (props.closeOnEscape) {
    if (e.target && e.target instanceof HTMLElement && e.target.nodeName === 'INPUT') {
      return;
    }

    if (isTopmostOverlay.value) {
      close();
    }
  }
});

onClickOutside(clickTarget, () => {
  if (props.backdropDismiss && isTopmostOverlay.value) {
    close();
  }
});

const showHeader = computed(() => !!(props.showCloseAction || slots.header));
const showFooter = computed(() => !!(slots.footer || slots.actions));
</script>

<script lang="ts">
// use normal <script> to declare options
export default {
  inheritAttrs: false,
};
</script>

<template>
  <Teleport to="body">
    <Transition name="modal">
      <div
        v-bind="$attrs"
        v-if="show"
        class="modal-mask"
        :class="{ 'modal-mask--has-overflow': scrollElHasOverflow }"
        :id="id">
        <div
          class="modal-container"
          :class="[
            props.size ? `modal-container--${props.size}` : undefined,
            props.translucentHeader ? 'modal-container--translucent-header' : undefined,
          ]"
          ref="clickTarget">
          <header
            class="modal-header"
            :class="{ 'modal-header--translucent': props.translucentHeader }"
            v-if="showHeader">
            <slot name="header" />
            <div class="modal-header-actions">
              <slot name="headerActions" />
              <button
                v-if="showCloseAction"
                @click.prevent="close()"
                class="btn btn--large btn--icon-only"
                :class="{ 'btn-white': props.translucentHeader }">
                <font-awesome-icon :icon="['far', 'xmark-circle']" />
              </button>
            </div>
          </header>
          <div
            class="modal-body"
            data-simplebar
            ref="modalBody">
            <slot />
          </div>
          <footer
            class="modal-footer"
            v-if="showFooter">
            <div
              v-if="$slots.footer"
              class="footer-slot"
              :class="{ 'mr-4': $slots.actions }">
              <slot name="footer" />
            </div>

            <div
              v-if="$slots.actions"
              class="actions-slot">
              <slot name="actions" />
            </div>
          </footer>
        </div>
      </div>
    </Transition>
  </Teleport>
</template>

<style>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* Also adjust transitionDuration const above if changed here */
  transition: opacity 0.3s ease;

  &--has-overflow {
    .modal-container {
      height: 100vh;

      .modal-body {
        height: 0;
      }

      .modal-footer {
        background: var(--color-gray-ultra-light);
      }
    }
  }
}

.modal-container {
  width: 480px;
  max-width: 90%;
  max-height: 80vh;
  margin: 7.5vh auto 0;
  background-color: #fff;
  border-radius: var(--border-radius);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;

  &.modal-container--translucent-header {
    position: relative;
    overflow-y: auto;

    .modal-header {
      background: transparent;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 9999;
    }

    .modal-body {
      overflow-y: initial;
    }
  }

  &.modal-container--sm {
    width: 360px;
  }

  &.modal-container--lg {
    width: 760px;
  }

  &.modal-container--xl {
    width: 1040px;
  }

  &.modal-container--2xl {
    width: 1280px;
  }
}

.modal-header,
.modal-footer {
  flex: none;
}

.modal-body {
  flex: 1 1 auto;
  overflow-y: auto;
}

.modal-header:not(:empty),
.modal-body,
.modal-footer:not(:empty) {
  padding: var(--space-3);
}

.modal-header {
  background: var(--color-gray-ultra-light);
  display: flex;
  justify-content: space-between;
  align-items: center;

  .modal-title > div:first-of-type {
    font-size: var(--font-size-l);
    font-weight: var(--font-weight-bold);
  }

  &:not(.modal-header--translucent) {
    .modal-header-actions .btn {
      --button-height: inherit;
      --button-color: var(--color-muted);
    }
  }

  &.modal-header--translucent .modal-header-actions {
    background: var(--color-white);
    border-radius: var(--border-radius);
    padding: var(--space-1) var(--space-3);
    box-shadow: var(--box-shadow);
  }

  .modal-header-actions {
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: var(--space-2);

    .btn {
      --button-height: 24px;
      --button-padding-x: 0;
      --button-padding-y: 0;
      flex: none;

      &:hover {
        --button-color: var(--color-secondary)
      }
    }
  }

  > div:not(.modal-header-actions) {
    flex: 1 1 auto;
  }
}

.modal-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .actions-slot {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    margin-left: auto;

    > .btn, .cta {
      margin: 0 var(--space-2);

      &:first-child {
        margin-right: 0;
      }

      &:last-child {
        margin-left: 0;
      }
    }

    .cta {
      margin: 0 var(--space-3);
    }
  }

  .footer-slot {
    font-size: var(--font-size-s);
  }
}

.modal-enter-from {
  opacity: 0;
}

.modal-leave-to {
  opacity: 0;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  transform: scale(1.1);
}
</style>
