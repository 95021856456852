<script setup lang="ts">
import { computed, ref } from 'vue';
import { lastValueFrom } from 'rxjs';
import { useCssVar } from '@vueuse/core';
import CircularProgress from '@/components/generic/CircularProgress.vue';
import { useGamification } from '@/composables/gamification';
import { redeemLevelBenefits$ } from '@/lib/firebase/functions';
import { useSystemMessage } from '@/composables/system-message';
import BaseButton from '@/components/BaseButton.vue';

interface Props {
  showPoints?: boolean;
  size?: 'xs' | 'sm' | 'lg' | 'xl';
  variant?: 'primary' | 'secondary-contrast';
}

const props = withDefaults(defineProps<Props>(), {
  showPoints: false,
  size: 'sm',
  variant: 'primary',
});

const { setSystemMessage } = useSystemMessage();

const sizeInPx = computed(() => {
  if (props.size === 'xl') {
    return 112;
  }

  if (props.size === 'lg') {
    return 80;
  }

  if (props.size === 'xs') {
    return 48;
  }

  return 56;
});

const {
  currentLevel,
  percentageOfNextLevel,
  levelIcon,
  currentPointsFormatted,
  redeemedLevel,
} = useGamification();

const canRedeemNewLevel = computed(() => currentLevel.value > 0 && redeemedLevel.value < currentLevel.value);

const isRedeeming = ref(false);
const redeemLevelBenefits = async () => {
  isRedeeming.value = true;
  await lastValueFrom(redeemLevelBenefits$(currentLevel.value))
    .then(() => {
      isRedeeming.value = false;
      setSystemMessage('gamification.redemptionSucceeded', 'success');
    })
    .catch(() => {
      isRedeeming.value = false;
      setSystemMessage('gamification.redemptionFailed', 'error');
    });
};

const fillColor = useCssVar(`--color-${props.variant}`);
const emptyColor = `${useCssVar(`--color-${props.variant}`).value}33`;
</script>

<template>
  <div
    class="current-level"
    :class="[`current-level--${size}`]">
    <div class="circle">
      <div class="icon-wrapper">
        <component :is="levelIcon" />
      </div>

      <CircularProgress
        :size="sizeInPx"
        :fill-color="fillColor ?? undefined"
        :empty-color="emptyColor"
        :progress="percentageOfNextLevel" />
    </div>

    <div class="content">
      <div class="level">
        {{ $t(`gamification.levels.${currentLevel}`) }}
      </div>
      <div
        v-if="showPoints"
        class="points">
        {{ `${currentPointsFormatted} ${$t('gamification.points')}` }}
      </div>
      <div
        v-if="canRedeemNewLevel"
        class="mt-1 redeem-cta">
        <BaseButton
          class="btn-primary btn--small"
          @click.stop="redeemLevelBenefits"
          :loading="isRedeeming">
          {{ $t('gamification.redeemCta') }}
        </BaseButton>
      </div>
    </div>
  </div>
</template>

<style>
.current-level {
  display: flex;
  align-items: center;

  --size: 3.5rem;
  --font-size: var(--font-size-s);
  --icon-padding: 0.75rem;

  &--xs {
    --size: 3rem;
    --font-size: var(--font-size-xs);
    --icon-padding: 0.625rem;
  }

  &--lg {
    --size: 5rem;
    --font-size: var(--font-size-m);
    --icon-padding: 1rem;
  }

  &--xl {
    --size: 7rem;
    --font-size: var(--font-size-l);
    --icon-padding: 1.25rem;
  }

  .circle {
    width: var(--size);
    height: var(--size);
    position: relative;
    flex: none;
  }

  .icon-wrapper {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    padding: var(--icon-padding);
  }

  .circular-progress {
    position: absolute !important;
    top: 0;
    left: 0;
  }

  .content {
    margin-left: var(--space-2);
    font-size: var(--font-size);
    line-height: var(--line-height-s);

    .level {
      font-weight: var(--font-weight-bold);
    }
  }
}
</style>
