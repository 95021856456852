<!-- eslint-disable -->
<template>
  <svg
    class="kc-icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 250 250">
    <line
      x1="225"
      y1="150"
      x2="25"
      y2="150" />
    <line
      x1="25"
      y1="225"
      x2="225"
      y2="225" />
    <line
      x1="175"
      y1="175"
      x2="200"
      y2="175" />
    <line
      x1="105"
      y1="187.5"
      x2="130"
      y2="187.5" />
    <line
      x1="37.5"
      y1="175"
      x2="62.5"
      y2="175" />
  </svg>
</template>
