import i18next from 'i18next';

import de from './lang/de.json';
import en from './lang/en.json';
import type { EnumValues } from './typings';

export const SUPPORTED_LANGUAGE = {
  DE: 'de',
  EN: 'en',
} as const;

export type SupportedLanguage = EnumValues<typeof SUPPORTED_LANGUAGE>;

i18next.init({
  lng: SUPPORTED_LANGUAGE.DE,
  supportedLngs: [SUPPORTED_LANGUAGE.DE, SUPPORTED_LANGUAGE.EN],
  fallbackLng: SUPPORTED_LANGUAGE.EN,
  debug: false,
  returnNull: false,
  preload: [SUPPORTED_LANGUAGE.DE, SUPPORTED_LANGUAGE.EN],
  defaultNS: 'lib',
  resources: {
    de: {
      lib: de,
    },
    en: {
      lib: en,
    },
  },
});

export const { t, exists: te } = i18next;

export function getT(lng: SupportedLanguage) {
  return i18next.getFixedT(lng);
}

export { t as translate };

export function addTranslations(namespace: string, resources: Record<SupportedLanguage, any>) {
  (Object.keys(resources) as SupportedLanguage[]).forEach((lng) => {
    i18next.addResourceBundle(lng, namespace, resources[lng]);
  });
}

export async function changeLanguage(lng: SupportedLanguage) {
  await i18next.changeLanguage(lng);
}
