import { ref, computed, readonly } from 'vue';
import type { UserLevel } from '@kcalc/lib/browser';
import { useThrottleFn } from '@vueuse/core';
import {
  getLevel, getTotalPoints, levelMatrix, formatPoints,
} from '@/lib/gamification';
import IconLevel0 from '@/components/gamification/levels/IconLevel0.vue';
import IconLevel1 from '@/components/gamification/levels/IconLevel1.vue';
import IconLevel2 from '@/components/gamification/levels/IconLevel2.vue';
import IconLevel3 from '@/components/gamification/levels/IconLevel3.vue';
import { userId$ } from '@/lib/firebase/auth';
import { useAuth } from '@/composables/firebase/auth';

const { user } = useAuth();

const currentPoints = ref(0);
const currentLevel = ref<UserLevel>(0);
const redeemedLevel = ref<UserLevel>(0);

const refresh = useThrottleFn(async () => {
  if (user && user.value?.profile?.verifiedAt) {
    currentPoints.value = await getTotalPoints(user.value.authUser, user.value.profile.verifiedAt.timestamp);
    currentLevel.value = await getLevel(user.value.authUser, user.value.profile.verifiedAt.timestamp);
    redeemedLevel.value = user.value.profile?.gamification?.redeemedLevel
      ? user.value.profile.gamification.redeemedLevel as UserLevel
      : 0;
  } else {
    currentPoints.value = 0;
    currentLevel.value = 0;
    redeemedLevel.value = 0;
  }
}, 10000);

userId$.subscribe(() => {
  refresh();
});

const currentPointsFormatted = computed(() => formatPoints(currentPoints.value));

const levelForIcon = computed(() => currentLevel.value);

const icons = {
  0: IconLevel0,
  1: IconLevel1,
  2: IconLevel2,
  3: IconLevel3,
};

const levelIcon = computed(() => icons[levelForIcon.value]);

const pointsForCurrentLevel = computed(() => {
  if (currentLevel.value < 1) {
    return 0;
  }

  return levelMatrix[currentLevel.value];
});

const pointsForNextLevel = computed(() => {
  if (currentLevel.value < 3) {
    return levelMatrix[currentLevel.value + 1];
  }

  return levelMatrix[3];
});

const percentageOfNextLevel = computed(() => (
  ((currentPoints.value - pointsForCurrentLevel.value) / (pointsForNextLevel.value - pointsForCurrentLevel.value)) * 100));

export function useGamification() {
  return {
    currentPoints: readonly(currentPoints),
    currentPointsFormatted,
    currentLevel: readonly(currentLevel),
    redeemedLevel: readonly(redeemedLevel),
    levelForIcon,
    percentageOfNextLevel,
    levelIcon,
    refresh,
  };
}
