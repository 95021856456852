<script setup lang="ts">
import IconLevel1 from '@/components/gamification/levels/IconLevel1.vue';
import IconLevel2 from '@/components/gamification/levels/IconLevel2.vue';
import IconLevel3 from '@/components/gamification/levels/IconLevel3.vue';
import BaseModal from '@/components/BaseModal.vue';
import CurrentLevel from '@/components/gamification/CurrentLevel.vue';
import { formatPoints, levelMatrix } from '@/lib/gamification';
import { isPlatform } from '@/lib/platform';

const level1Points = formatPoints(levelMatrix[1]);
const level2Points = formatPoints(levelMatrix[2]);
const level3Points = formatPoints(levelMatrix[3]);

const level1Advantages = (() => (isPlatform('ecodemy') ? 'gamification.advantages.ecodemy.level1' : 'gamification.advantages.level1'))();
</script>

<template>
  <BaseModal
    :title="$t('gamification.overviewModal.title')"
    size="lg">
    <template #default>
      <div class="gamification-modal">
        <div class="text-l text-bold mb-4 text-center">
          {{ $t('gamification.overviewModal.currentLevel.headline') }}
        </div>
        <div class="flex items-center justify-center">
          <CurrentLevel
            size="xl"
            :show-points="true" />
        </div>

        <div class="divider" />

        <div class="text-l text-bold mb-4 text-center">
          {{ $t('gamification.overviewModal.levels.headline') }}
        </div>

        <div class="levels">
          <div class="level-wrapper">
            <div class="flex items-center">
              <div class="level-icon">
                <IconLevel1 />
              </div>

              <div class="content">
                <div class="level">
                  {{ $t('gamification.levels.1') }}
                </div>
                <div class="points">
                  {{ level1Points }} {{ $t('gamification.points') }}
                </div>
              </div>
            </div>

            <div class="advantages">
              <strong>{{ $t('gamification.advantages.headline') }}</strong>

              <div v-html="$t(level1Advantages)" />
            </div>
          </div>

          <div class="level-wrapper">
            <div class="flex items-center">
              <div class="level-icon">
                <IconLevel2 />
              </div>

              <div class="content">
                <div class="level">
                  {{ $t('gamification.levels.2') }}
                </div>
                <div class="points">
                  {{ level2Points }} {{ $t('gamification.points') }}
                </div>
              </div>
            </div>

            <div class="advantages">
              <strong>{{ $t('gamification.advantages.headline') }}</strong>

              <div v-html="$t('gamification.advantages.level2')" />
            </div>
          </div>

          <div class="level-wrapper">
            <div class="flex items-center">
              <div class="level-icon">
                <IconLevel3 />
              </div>

              <div class="content">
                <div class="level">
                  {{ $t('gamification.levels.3') }}
                </div>
                <div class="points">
                  {{ level3Points }} {{ $t('gamification.points') }}
                </div>
              </div>
            </div>

            <div class="advantages">
              <strong>{{ $t('gamification.advantages.headline') }}</strong>

              <div v-html="$t('gamification.advantages.level3')" />
            </div>
          </div>
        </div>

        <div class="divider" />

        <div class="text-l text-bold mb-4 text-center">
          {{ $t('gamification.overviewModal.points.headline') }}
        </div>

        <div class="flex valuable-things">
          <div class="px-4">
            <strong>
              {{ $t('gamification.targets.actions.headline') }}
            </strong>
            <div v-html="$t('gamification.targets.actions.content')" />
          </div>

          <div class="px-4">
            <strong>
              {{ $t('gamification.targets.time.headline') }}
            </strong>
            <div v-html="$t('gamification.targets.time.content')" />
          </div>

          <div class="px-4">
            <strong>
              {{ $t('gamification.targets.campaigns.headline') }}
            </strong>
            <div v-html="$t('gamification.targets.campaigns.content')" />
          </div>
        </div>
      </div>
    </template>
  </BaseModal>
</template>

<style>
.gamification-modal {
  .divider {
    margin: var(--space-5) 0;
    height: 2px;
    background: var(--color-gray-ultra-light);
    width: 100%;
  }

  .levels {
    display: flex;
    margin-top: var(--space-4);
    justify-content: space-between;

    .level-wrapper {
      padding: 0 var(--space-4);
    }

    .content {
      margin-left: var(--space-2);
      line-height: var(--line-height-m);

      .level {
        font-weight: var(--font-weight-bold);
      }

      .points {
        font-size: var(--font-size-s);
      }
    }

    div.level-icon {
      padding: var(--space-3);
      border-radius: 100%;
      border: 4px solid var(--color-primary);
      width: 5rem;
      height: 5rem;
    }

    .advantages {
      margin-top: var(--space-3);
      font-size: var(--font-size-m);

      ul {
        margin-top: var(--space-2);
        padding-left: var(--space-3);
        font-size: var(--font-size-s);
        line-height: var(--line-height-s);

        li:not(:last-child) {
          margin-bottom: var(--space-2);
        }
      }
    }
  }

  .valuable-things {
    ul {
      margin-top: var(--space-2);
      padding-left: var(--space-3);
      font-size: var(--font-size-s);
      line-height: var(--line-height-s);

      li:not(:last-child) {
        margin-bottom: var(--space-2);
      }
    }
  }
}
</style>
